import React from "react";

export default function Hero() {
    return (
        <div className="section" style={{ padding: 0 }}>
            <img
                src="wallpaper.png"
                alt="dunsparce destiny"
                className="display-img"
            />
        </div>
    );
}
